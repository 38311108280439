"use client";
import { cn, useAssets, useHref } from "@/lib/utils";
import Image from "next/image";
import { Link } from "@/navigation";
import { useLocale, useTranslations } from "next-intl";
import { usePathname } from "@/navigation";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export default function AccordionLocaleSwitcher() {
  const asset = useAssets();
  const locale = useLocale();
  const pathname = usePathname();

  return (
    <AccordionItem value="Language">
      <AccordionTrigger>Bahasa</AccordionTrigger>
      <AccordionContent>
        <div className="text-xs h-14 font-semibold text-[#667085] mt-4">
          <Link
            href={pathname as any}
            locale="id"
            className={cn(
              locale == "id" && "text-[#F5BE01] bg-[#FFFAEC]",
              "inline-flex items-center gap-4 w-full h-14 px-6"
            )}
          >
            <Image
              alt="BroilerX Bahasa"
              src={asset.flagID}
              className="w-4 h-4"
            />
            Bahasa
          </Link>
        </div>
        <div className="text-xs h-14 font-semibold text-[#667085]">
          <Link
            href={pathname as any}
            locale="en"
            className={cn(
              locale == "en" && "text-[#F5BE01] bg-[#FFFAEC]",
              "inline-flex items-center gap-4 w-full h-14 px-6"
            )}
          >
            <Image
              alt="BroilerX English"
              src={asset.flagEN}
              className="w-4 h-4"
            />
            English
          </Link>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
}
