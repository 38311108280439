import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"500\",\"style\":\"normal\",\"preload\":true}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/public/assets/countries/EN.png");
import(/* webpackMode: "eager" */ "/app/public/assets/countries/ID.png");
import(/* webpackMode: "eager" */ "/app/public/assets/founders/ceo.png");
import(/* webpackMode: "eager" */ "/app/public/assets/founders/coo.png");
import(/* webpackMode: "eager" */ "/app/public/assets/founders/cto.png");
import(/* webpackMode: "eager" */ "/app/public/assets/founders/founder-bg-card.png");
import(/* webpackMode: "eager" */ "/app/public/assets/hiring-process/1.png");
import(/* webpackMode: "eager" */ "/app/public/assets/hiring-process/2.png");
import(/* webpackMode: "eager" */ "/app/public/assets/hiring-process/3.png");
import(/* webpackMode: "eager" */ "/app/public/assets/hiring-process/4.png");
import(/* webpackMode: "eager" */ "/app/public/assets/hiring-process/5.png");
import(/* webpackMode: "eager" */ "/app/public/assets/hiring-process/6.png");
import(/* webpackMode: "eager" */ "/app/public/assets/hiring-process/arrow.png");
import(/* webpackMode: "eager" */ "/app/public/assets/icon/naik.svg");
import(/* webpackMode: "eager" */ "/app/public/assets/icon/stag_naik.svg");
import(/* webpackMode: "eager" */ "/app/public/assets/icon/stag_turun.svg");
import(/* webpackMode: "eager" */ "/app/public/assets/icon/stag.svg");
import(/* webpackMode: "eager" */ "/app/public/assets/icon/turun.svg");
import(/* webpackMode: "eager" */ "/app/public/assets/life-at-bx/1.png");
import(/* webpackMode: "eager" */ "/app/public/assets/life-at-bx/2.png");
import(/* webpackMode: "eager" */ "/app/public/assets/life-at-bx/3.png");
import(/* webpackMode: "eager" */ "/app/public/assets/life-at-bx/4.png");
import(/* webpackMode: "eager" */ "/app/public/assets/life-at-bx/5.png");
import(/* webpackMode: "eager" */ "/app/public/assets/life-at-bx/6.png");
import(/* webpackMode: "eager" */ "/app/public/assets/life-at-bx/7.png");
import(/* webpackMode: "eager" */ "/app/public/assets/officials/bx-logo-white-2.png");
import(/* webpackMode: "eager" */ "/app/public/assets/officials/bx-logo.png");
import(/* webpackMode: "eager" */ "/app/public/assets/ornaments/ornament-chicken-head.png");
import(/* webpackMode: "eager" */ "/app/public/assets/ornaments/ornament-chicken-side.png");
import(/* webpackMode: "eager" */ "/app/public/assets/ornaments/ornament-chickend-foot.png");
import(/* webpackMode: "eager" */ "/app/public/assets/share-socmed/fb.png");
import(/* webpackMode: "eager" */ "/app/public/assets/share-socmed/ig.png");
import(/* webpackMode: "eager" */ "/app/public/assets/share-socmed/li.png");
import(/* webpackMode: "eager" */ "/app/public/assets/share-socmed/tg.png");
import(/* webpackMode: "eager" */ "/app/public/assets/share-socmed/wa.png");
import(/* webpackMode: "eager" */ "/app/public/assets/share-socmed/x.png");
import(/* webpackMode: "eager" */ "/app/public/assets/update-harga-ayam/arrow-up.png");
import(/* webpackMode: "eager" */ "/app/public/assets/update-harga-ayam/chicken.png");
import(/* webpackMode: "eager" */ "/app/public/assets/update-harga-ayam/price-tag-blur.png");
import(/* webpackMode: "eager" */ "/app/public/assets/update-harga-ayam/price-tag.png");
import(/* webpackMode: "eager" */ "/app/public/assets/util-karir.png");
import(/* webpackMode: "eager" */ "/app/public/assets/vendors/vendor-app-store.png");
import(/* webpackMode: "eager" */ "/app/public/assets/vendors/vendor-play-store.png");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/globals.css");
import(/* webpackMode: "eager" */ "/app/src/components/modules/app-navbar/index.tsx")