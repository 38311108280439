"use client";
import { cn, useAssets, useHref } from "@/lib/utils";
import { Link } from "@/navigation";
import Image from "next/image";
import { useLocale, useTranslations } from "next-intl";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { usePathname } from "@/navigation";

export default function MenubarLocaleSwitcher() {
  const asset = useAssets();
  const locale = useLocale();
  const pathname = usePathname();

  const flagMapper = (currentLocale: any) => {
    const map = {
      id: asset.flagID,
      en: asset.flagEN,
    } as any;
    return map[currentLocale] ?? map["id"];
  };

  return (
    <Menubar className="ml-auto border-none border-0">
      <MenubarMenu>
        <MenubarTrigger>
          <div className="w-6 h-6 cursor-pointer">
            <Image
              alt="BroilerX Bahasa"
              src={flagMapper(locale)}
              className="w-full h-full"
            />
          </div>
        </MenubarTrigger>
        <MenubarContent className="py-2 px-2 transition-all ease-in-out duration-75">
          <MenubarItem className="text-xs h-14 font-semibold text-[#667085] ">
            <Link
              href={pathname as any}
              locale="id"
              className={cn(
                locale == "id" && "text-[#F5BE01] bg-[#FFFAEC]",
                "inline-flex items-center gap-4 w-full h-full px-4"
              )}
            >
              <Image
                alt="BroilerX Bahasa"
                src={asset.flagID}
                className="w-4 h-4"
              />
              Bahasa
            </Link>
          </MenubarItem>
          <MenubarItem className="text-xs h-14 font-semibold text-[#667085]">
            <Link
              href={pathname as any}
              locale="en"
              className={cn(
                locale == "en" && "text-[#F5BE01] bg-[#FFFAEC]",
                "inline-flex items-center gap-4 w-full h-full  px-4"
              )}
            >
              <Image
                alt="BroilerX English"
                src={asset.flagEN}
                className="w-4 h-4"
              />
              English
            </Link>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
}
