import { Pathnames } from 'next-intl/navigation';

export const defaultLocale = 'id';

export const locales = ['en', 'id'] as const;

export const localePrefix = process.env.NEXT_PUBLIC_LOCALE_PREFIX === 'never' ? 'never' : 'as-needed';

export const port = process.env.PORT || 3000;
export const host = process.env.NEXT_PUBLIC_URL
    ? `${process.env.NEXT_PUBLIC_URL}`
    : `localhost:${port}`;

export const pathnames = {
    '/': '/',
    "/faq": {
        "id": "/faq",
        "en": "/faq"
    },
    "/hubungi-kami": {
        "id": "/hubungi-kami",
        "en": "/contact-us"
    },
    "/karir": {
        "id": "/karir",
        "en": "/career"
    },
    "/karkas-dan-telur": {
        "id": "/karkas-dan-telur",
        "en": "/carcas-egg"
    },
    "/pendanaan-budidaya-kemitraan": {
        "id": "/pendanaan-budidaya-kemitraan",
        "en": "/cultivation-funding"
    },
    "/pendanaan-perbaikan-kandang": {
        "id": "/pendanaan-perbaikan-kandang",
        "en": "/upgrade-farm-funding"
    },
    "/privacy": {
        "id": "/privacy",
        "en": "/privacy"
    },
    "/profil": {
        "id": "/profil",
        "en": "/profile"
    },
    "/sapronak": {
        "id": "/sapronak",
        "en": "/sapronak"
    },
    "/teknologi/app": {
        "id": "/teknologi/app",
        "en": "/solution/app"
    },
    "/teknologi/iot": {
        "id": "/teknologi/iot",
        "en": "/solution/iot"
    },
    "/teknologi/erp": {
        "id": "/teknologi/erp",
        "en": "/solution/erp"
    },
    "/temukan-kami": {
        "id": "/temukan-kami",
        "en": "/find-us"
    },
    "/term": {
        "id": "/term",
        "en": "/term"
    },
    "/update-harga-ayam": {
        "id": "/update-harga-ayam",
        "en": "/daily-livebird-update-price"
    },
    "/user-guide": {
        "id": "/panduan-pengguna",
        "en": "/user-guide"
    },
    // "/user-guide/overview": {
    //     "id": "user-guider/overview",
    //     "en": "user-guider/overview"
    // },
    // "/user-guider/kandang": {
    //     "id": "/user-guider/kandang",
    //     "en": "user-guider/house"
    // },
    // "/user-guider/mulai-periode/beranda": {
    //     "id": "/user-guider/mulai-periode/beranda",
    //     "en": "user-guider/rearing-periode/beranda"
    // }
} satisfies Pathnames<typeof locales>;

export type AppPathnames = keyof typeof pathnames;